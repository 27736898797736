import React, { forwardRef } from "react";
import moment from "moment";
import "./print.css";

const OrderPrint = forwardRef(({ orderDetail }, ref) => {

    return (
        <div ref={ref} className="fs-6" style={{ width: "273px" }}>
            <div className="m-1">
                <div className="row">
                    <div className="d-flex justify-content-center">
                        <div className="d-flex flex-column">
                            <h3 className="text-center m-0">Mr Singh's Pizza</h3>
                            <p className="text-center m-0 p-0">Pure Vegetarian</p>
                        </div>
                    </div>
                    <p className="text-center mb-0">
                        {orderDetail?.storeAddress ? orderDetail?.storeAddress : ""}
                    </p>
                    <p className="text-center mb-1">905-500-4000</p>
                </div>
                <div className="row">
                    {orderDetail && (
                        <>
                            <div className="d-flex justify-content-between">
                                <p className="m-0">#{orderDetail?.orderCode}</p>
                                <p className="m-0">
                                    Date : {moment(orderDetail?.created_at).format("DD-MM-YYYY")}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="m-0">
                                    Time : {moment(orderDetail?.created_at).format("hh:mm A")}
                                </p>
                            </div>
                            <div className="d-flex justify-content-between">


                                <p className="m-0 fw-bold">{orderDetail?.customerName}</p>
                                <p className="m-0 fw-bold">Ph. {orderDetail?.formattedNumber}</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <p className="m-0 fw-bold"

                                    style={{
                                        fontSize: "28px"
                                    }}>Order Cancelled</p>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                                <p className="m-0 fw-bold">Grand Total:</p>
                                <p className="m-0 fw-bold">$ {Number(orderDetail?.grandTotal).toFixed(2)}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default OrderPrint;