export const getSpecialDetails = (
  data,
  getSpecialDetailsApi,
  setGetSpecialData
) => {
  getSpecialDetailsApi(data)
    .then((res) => {
      setGetSpecialData(res.data.data);
    })
    .catch((err) => { });
};

export const getSpecialDataCall = () => { };
