import React, { useEffect, useState } from "react";
import { sidesApi } from "../../API/ongoingOrder";
import $ from "jquery";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../reducer/cartReducer";

function SidesMenu({ discount, taxPer, payloadEdit, setPayloadEdit }) {
  const [sidesData, setSidesData] = useState();
  const [quantity, setQuantity] = useState(1);
  const [sidesArr, setSidesArr] = useState([]);
  const [comments, setComments] = useState("");
  let cartdata = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    sides();
  }, []);

  // handle Quantity
  const handleQuantity = (e, side) => {
    // const inputValue = e.target.value;
    let index = sidesArr?.findIndex((item) => item.sideCode === side.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];
      arr[index].qty = e.target.value <= 0 ? 1 : e.target.value;
      setSidesArr(arr);
      updateInCart({
        ...arr[index],
        qty: e.target.value <= 0 ? 1 : e.target.value,
      });
    } else {
      setSidesArr([
        ...sidesArr,
        { ...side, qty: e.target.value <= 0 ? 1 : e.target.value },
      ]);
      updateInCart({ ...side, qty: e.target.value <= 0 ? 1 : e.target.value });
    }
  };

  const handleSidesLineChange = (e, data) => {
    let index = sidesArr?.findIndex((item) => item.sideCode === data.sideCode);
    let selectedSideLine = sidesArr[index]?.combination?.filter(
      (item) => item.lineCode === e.target.value
    );
    let obj = sidesData.find((item) => item.sideCode === data.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];

      arr[index].combination = obj.combination.filter(
        (item) => item.lineCode === e.target.value
      );
      setSidesArr(arr);
      updateInCart({
        ...arr[index],
        combination: obj.combination.filter(
          (item) => item.lineCode === e.target.value
        ),
      });
    } else {
      let selectedSideLine = data?.combination?.filter(
        (item) => item.lineCode === e.target.value
      );
      setSidesArr([{ ...data, combination: selectedSideLine }]);
      updateInCart({ ...data, combination: selectedSideLine });
    }
  };
  const updateInCart = (data) => {
    let cart = JSON.parse(localStorage.getItem("CartData"));

    let tempPayload = [...cartdata];

    const updatedCartId = cartdata?.findIndex(
      (item) => item?.productCode === data?.sideCode
    );
    let cartCode;
    let customerCode;
    if (cart !== null && cart !== undefined) {
      cartCode = cart?.cartCode;
      customerCode = cart?.customerCode;
    }
    let price = data?.combination[0]?.price;

    let totalAmount = 0;

    totalAmount =
      Number(price) * (data?.qty !== undefined ? Number(data?.qty) : 1);

    const payload = {
      id: updatedCartId !== -1 ? cartdata[updatedCartId]?.id : uuidv4(),
      cartCode: cartCode ? cartCode : "#NA",
      customerCode: customerCode ? customerCode : "#NA",
      cashierCode: localStorage.getItem("cashierCode"),
      productCode: data?.sideCode,
      productName: data?.sideName,
      productType: "side",
      config: {
        lineCode: data?.combination[0]?.lineCode,
        sidesSize: data?.combination[0]?.size,
        sideType: data?.type,
      },
      quantity: data?.qty ? data?.qty : 1,
      price: data?.combination[0]?.price,
      amount: totalAmount.toFixed(2),
      discountAmount: discount,
      taxPer: taxPer,
      pizzaSize: "",
      comments: data?.comment ? data?.comment : "",
    };

    if (updatedCartId !== -1) {
      tempPayload[updatedCartId] = payload;
    } else {
      tempPayload.unshift(payload);
    }
    dispatch(addToCart([...tempPayload]));
  };

  // Onclick handle Add To Cart & API - Add To Cart
  const handleAddToCart = async (e, sideCode, Obj) => {
    if (
      payloadEdit !== undefined &&
      payloadEdit.productType === "side" &&
      payloadEdit?.productCode != Obj?.sideCode
    ) {
      toast.error("complete your last edit first");
      return;
    } else {
      e.preventDefault();
      const updatedCartId = cartdata?.findIndex(
        (item) => item?.productCode === Obj?.sideCode
      );
      let cart = JSON.parse(localStorage.getItem("CartData"));
      let cartCode;
      let customerCode;
      let lineCode = $("#combination-" + sideCode)
        .find(":selected")
        .attr("data-key");

      if (cart !== null && cart !== undefined) {
        cartCode = cart.cartCode;
        customerCode = cart.customerCode;
      }

      const selectedSideForNewItem = sidesArr?.filter(
        (sides) => sides.sideCode === sideCode
      );
      const selectedCombination =
        selectedSideForNewItem[0]?.combination?.filter(
          (data) => data.lineCode === lineCode
        );

      const selectedCombinationObj = Obj?.combination?.filter(
        (data) => data.lineCode === lineCode
      );

      let price =
        selectedCombination !== undefined && selectedCombination[0].price
          ? selectedCombination[0]?.price
          : selectedCombinationObj[0]?.price;

      let totalAmount = 0;

      totalAmount =
        Number(price) *
        (selectedSideForNewItem[0]?.qty !== undefined
          ? Number(selectedSideForNewItem[0]?.qty)
          : 1);

      if (selectedSideForNewItem.length === 0) {
        const payload = {
          id: uuidv4(),
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: Obj.sideCode,
          productName: Obj?.sideName,
          productType: "side",
          config: {
            lineCode: selectedCombinationObj[0].lineCode,
            sidesSize: selectedCombinationObj[0].size,
            sidesType: Obj?.type,
          },
          quantity: 1,
          price: selectedCombinationObj[0].price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: "",
        };
        let temp = sidesData.map((item) => {
          return {
            ...item,
            qty: 1,
            comment: "",
          };
        });
        setSidesData(temp);
        setSidesArr([]);
        dispatch(addToCart([payload, ...cartdata]));
        toast.success(`${Obj.sideName} Added Successfully`);
        return;
      }

      setSidesArr([
        ...sidesArr,
        {
          ...selectedSideForNewItem,
          qty: 1,
        },
      ]);

      const selectedSide = sidesArr?.filter(
        (sides) => sides.sideCode === sideCode
      );

      if (payloadEdit !== undefined && payloadEdit.productType === "side") {
        const payloadForEdit = {
          id: payloadEdit?.id,
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: selectedSide[0].sideCode,
          productName: selectedSide[0].sideName,
          productType: "side",
          config: {
            lineCode: selectedSide[0].combination[0].lineCode,
            sidesSize: selectedSide[0].combination[0].sidesSize
              ? selectedSide[0].combination[0].sidesSize
              : selectedSide[0].combination[0].size,
            sideType: selectedSide[0].type,
          },
          quantity: selectedSide[0].qty,
          price: selectedSide[0].price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: selectedSide[0].comment,
        };
        const updatedCart = cartdata.findIndex(
          (item) => item.id === payloadEdit.id
        );
        let tempPayload = [...cartdata];
        tempPayload[0] = payloadForEdit;
        dispatch(addToCart([...tempPayload]));
        setPayloadEdit();
        let temp = sidesData.map((item) => {
          return {
            ...item,
            qty: 1,
          };
        });
        setSidesData(temp);
        setSidesArr([]);
        setQuantity(1);
      } else {
        let tempPayload = [...cartdata];

        const payload = {
          id: updatedCartId !== -1 ? cartdata[updatedCartId].id : uuidv4(),
          customerCode: customerCode ? customerCode : "#NA",
          cashierCode: localStorage.getItem("cashierCode"),
          productCode: selectedSideForNewItem[0]?.sideCode,
          productName: selectedSideForNewItem[0]?.sideName,
          productType: "side",
          config: {
            lineCode: selectedCombination[0]?.lineCode,
            sidesSize: selectedCombination[0]?.size,
            sideType: selectedSideForNewItem[0]?.type,
          },
          quantity: selectedSideForNewItem[0]?.qty
            ? selectedSideForNewItem[0]?.qty
            : 1,
          price: selectedCombination[0]?.price,
          amount: totalAmount.toFixed(2),
          discountAmount: discount,
          taxPer: taxPer,
          pizzaSize: "",
          comments: selectedSideForNewItem[0].comment,
        };
        let temp = sidesData.map((item) => {
          return {
            ...item,
            qty: 1,
          };
        });
        setSidesData(temp);
        setSidesArr([]);
        // let
        if (updatedCartId !== -1) {
          tempPayload[updatedCartId] = payload;
        } else {
          tempPayload.unshift(payload);
        }
        dispatch(addToCart([...tempPayload]));
        toast.success(
          `${selectedSideForNewItem[0]?.sideName} Added Successfully`
        );
      }
    }
  };

  useEffect(() => {
    if (payloadEdit !== undefined && payloadEdit.productType === "side") {
      setSidesArr([
        {
          sideCode: payloadEdit?.productCode,
          sideName: payloadEdit?.productName,
          price: payloadEdit?.price,
          qty: payloadEdit?.quantity,
          combination: [
            {
              ...payloadEdit?.config,
              price: payloadEdit?.price,
              size: payloadEdit?.config?.sidesSize,
            },
          ],
          comment: payloadEdit?.comments,
        },
      ]);
    }
  }, [payloadEdit]);

  //API - Sides
  const sides = () => {
    sidesApi()
      .then((res) => {
        setSidesData(
          res.data.data?.map((item) => {
            return {
              ...item,
              qty: 1,
            };
          })
        );
      })
      .catch((err) => {
        console.error("ERROR From SidesMenu API: ", err);
      });
  };
  const handleComment = (e, data) => {
    let index = sidesArr?.findIndex((item) => item.sideCode === data.sideCode);
    let selectedSideLine = sidesArr[index]?.combination?.filter(
      (item) => item.lineCode === e.target.value
    );
    let obj = sidesData.find((item) => item.sideCode === data.sideCode);
    if (index !== -1) {
      let arr = [...sidesArr];

      arr[index] = { ...arr[index], comment: e.target.value };
      setSidesArr(arr);
    } else {
      // let selectedSideLine = data?.combination?.filter(
      //   (item) => item.lineCode === e.target.value
      // );
      setSidesArr([{ ...obj, comment: e.target.value }]);
    }
  };

  return (
    <>
      <ul
        className='list-group'
        style={{ overflowY: "scroll", height: "30rem" }}
      >
        {sidesData?.map((data, index) => {
          let comm = sidesArr?.findIndex(
            (item) => item.sideCode === data.sideCode
          );
          let obj = sidesArr?.find((item) => item.sideCode === data.sideCode);
          return (
            <li
              className='list-group-item'
              key={data.sideCode + "sidesData" + index}
            >
              <div className='d-flex justify-content-between align-items-end py-2 px-1'>
                <div className='d-flex justify-content-center w-auto'>
                  {/*
                    <img
                      className='rounded'
                      src={data.image === "" ? `${specialImg1}` : data.image}
                      width='50px'
                      height='50px'
                      alt=''
                    />
                    */}
                </div>
                <div className='d-flex justify-content-center flex-column py-1 w-100'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h6 className='mb-2'>
                      {data.sideName}{" "}
                      <span className={"badge-" + data.type}>
                        ({data.type})
                      </span>
                    </h6>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <select
                      className='form-select'
                      style={{ width: "35%" }}
                      id={"combination-" + data.sideCode}
                      onChange={(e) => {
                        handleSidesLineChange(e, data);
                      }}
                      value={
                        comm !== -1
                          ? sidesArr[comm]?.combination[0]?.lineCode
                          : null
                      }
                    >
                      {data?.combination?.map((combinationData) => {
                        return (
                          <>
                            <option
                              key={combinationData.lineCode}
                              data-key={combinationData.lineCode}
                              data-price={combinationData.price}
                              value={combinationData.lineCode}
                            >
                              {combinationData.size} - $ {combinationData.price}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    <input
                      type='number'
                      className='form-control'
                      style={{ width: "20%" }}
                      onChange={(e) => handleQuantity(e, data)}
                      step={1}
                      min={1}
                      value={obj !== undefined ? obj.qty : data.qty}
                      max={100}
                      defaultValue={1}
                    />
                    <button
                      type='button'
                      className='btn btn-sm customize py-1 px-2'
                      style={{ width: "auto" }}
                      onClick={(e) => handleAddToCart(e, data.sideCode, data)}
                    >
                      {payloadEdit !== undefined &&
                        payloadEdit.productType === "side" &&
                        obj !== undefined
                        ? "Edit"
                        : "Add To Cart"}
                    </button>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    {/* <label htmlFor="comment">comment </label> */}
                    <input
                      id='comment'
                      type='text'
                      value={obj?.comment !== undefined ? obj?.comment : ""}
                      className='form-control mt-2'
                      onChange={(e) => handleComment(e, data)}
                      placeholder='eg. comment'
                    />
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}
export default SidesMenu;
