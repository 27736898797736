import "./App.css";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import ForgetPass from "./Pages/auth/ForgetPass";
import Login from "./Pages/auth/Login";
import ResetPass from "./Pages/auth/ResetPass";
import Invoices from "./Pages/invoices/Invoices";
import OrderList from "./Pages/orderscreen/OrderList";
import Report from "./Pages/report/Report";
import RestrictedPage from "./Pages/RestrictedPage";
import OngoingOrder from "./Pages/ongoingOrders/NewOrder";
import { useDispatch, useSelector } from "react-redux";
import Order from "./Pages/order/Order";
import AuthLayout from "./layout/AuthLayout";
import {
  cashierDetails,
  sendNotification,
  updateFirebaseId,
} from "./API/ongoingOrder";
import { GlobalProvider } from "./context/GlobalContext";
import { setNotification, setPrintRef } from "./reducer/cartReducer";
import Profile from "./Pages/dashboard/Profile";
import PasswordChange from "./Pages/dashboard/PasswordChange";
import HelmetHeader from "./components/order/HelmetHeader";
import OrderNotification from "./Pages/onlineordernotification/OrderNotification";

function App() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.userData);
  const role = user?.role ?? "R_3";

  return (
    <>
      <HelmetHeader />
      <GlobalProvider>
        <Routes>
          <Route path="/" index exact element={<Login />} />
          <Route path="/forget-password" exact element={<ForgetPass />} />
          <Route path="/reset-password" exact element={<ResetPass />} />
          <Route
            path="/ongoing-orders"
            element={
              <AuthLayout>
                <OngoingOrder />
              </AuthLayout>
            }
          />
          <Route
            path="/orders"
            element={
              <AuthLayout>
                <Order />
              </AuthLayout>
            } />
          <Route
            path="/invoices"
            element={
              <AuthLayout>
                <Invoices />
              </AuthLayout>
            } />
          {role !== 'R_4' &&
            <>
              <Route
                path="/orderscreen"
                element={
                  <AuthLayout>
                    <OrderList />
                  </AuthLayout>
                } />
              <Route path="/ordernotification" element={<OrderNotification />} />
            </>
          }

          {/* <Route path='/reports' element={<Report />} /> */}
          <Route
            path="/profile-update"
            element={
              <AuthLayout>
                <Profile />
              </AuthLayout>
            }
          />
          <Route
            path="/password-change"
            element={
              <AuthLayout>
                <PasswordChange />
              </AuthLayout>
            }
          />





          {/* Page For Restricted condition */}
          <Route path="/restricted-page" element={<RestrictedPage />} />
        </Routes>
      </GlobalProvider>
    </>
  );
}

export default App;
